import { pluralize } from "@schtappe/utils"

const oneYear = (60 * 60 * 24 * 365 * 1000)
const oneMonth = (60 * 60 * 24 * 30 * 1000)
const oneDay = (60 * 60 * 24 * 1000)
const oneHour = (60 * 60 * 1000)
const oneMinute = (60 * 1000)
const oneSecond = (1000)
export const getHumanDateDiff = (from, to) => {
        const result = []

        const ms = to - from
        const years = ms / oneYear
        const months = (ms % oneYear) / oneMonth
        const days = ((ms % oneYear) % oneMonth) / oneDay
        const hours = (((ms % oneYear) % oneMonth) % oneDay) / oneHour
        const minutes = ((((ms % oneYear) % oneMonth) % oneDay) % oneHour) / oneMinute
        const seconds = (((((ms % oneYear) % oneMonth) % oneDay) % oneHour) % oneMinute) / oneSecond;

        (years >= 1) && result.push(`${years.toFixed(0)} ${pluralize(years, "year", "years")}`);
        (months >= 1) && result.push(`${months.toFixed(0)} ${pluralize(months, "month", "months")}`);
        (days >= 1) && result.push(`${days.toFixed(0)} ${pluralize(days, "day", "days")}`);
        (hours >= 1) && result.push(`${hours.toFixed(0)} ${pluralize(hours, "hour", "hours")}`);
        (minutes >= 1) && result.push(`${minutes.toFixed(0)} ${pluralize(minutes, "minute", "minutes")}`);
        (seconds >= 1) && result.push(`${seconds.toFixed(0)} ${pluralize(seconds, "second", "seconds")}`);
        result[1] = "ago" // TODO: WTF?

        return result.slice(0, 2).join(" ")
}
