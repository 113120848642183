import { Worklist, exportToJson } from "./models/worklist.js"
import { getHumanDateDiff } from "./utils.js"

const toJson = (text) => {
        return new Promise((resolve, reject) => {
                try {
                        resolve(JSON.parse(text))
                } catch (error) {
                        reject(error)
                }
        })
}

const CHECKEROO = {
        DB_VERSION: 2,
}

const routerMachine = {
        current: "index",
        states: {
                index: {
                        on: {
                                CREATE: "create",
                                EDIT: "edit",
                                SESSION: "session",
                                PREVIOUS: "previous",
                        },
                },
                create: {
                        on: {
                                SUBMIT: "adding",
                                CANCEL: "index",
                        },
                },
                adding: {
                        on: {
                                ADD_SUCCESS: "index",
                                ADD_FAILURE: "",
                        },
                },
                edit: {
                        on: {
                                UPDATE: "updating",
                                CANCEL: "index",
                        },
                },
                updating: {
                        on: {
                                UPDATE_SUCCESS: "index",
                                UPDATE_FAILURE: "",
                        },
                },
                session: {
                        on: {
                                SAVE: "saving",
                                CANCEL: "index",
                                PREVIOUS: "previous",
                        },
                },
                saving: {
                        on: {
                                SAVE_SUCCESS: "index",
                                SAVE_FAILURE: "",
                        },
                },
                previous: {
                        on: {
                                CANCEL: "index",
                                SESSION: "session",
                        },
                },
        },
}

function createActor(machine) {
        const result = {
                subscribers: [],
                send(event) {
                        const handlers = machine.states[result.state()].on || {}
                        const nextState = handlers[event.type]
                        if (!(nextState && machine.states[nextState])) return
                        machine.current = nextState
                        result.transitioned(event)
                },
                state() {
                        return machine.current
                },
                transitioned(event) {
                        result.subscribers.forEach((subscriber) => {
                                subscriber({ state: result.state(), event })
                        })
                },
                subscribe(fn) {
                        result.subscribers.push(fn)
                        const index = result.subscribers.length - 1
                        const unsubscribe = () => subscribers.splice(index, 1)
                        return unsubscribe
                }
        }
        return result
}

const actor = createActor(routerMachine)
let db = null
const openDatabase = () => {
        return new Promise((resolve, reject) => {
                const request = window.indexedDB.open("checkeroo", CHECKEROO.DB_VERSION)
                request.onupgradeneeded = (event) => {
                        const db = event.target.result

                        // TODO: add index, e.g.,
                        // store.createIndex("idIndex", "id", { unique: true })

                        switch (event.oldVersion) {
                                case 0: {
                                        db.createObjectStore("checklistStore", { keyPath: "id", autoIncrement: true })
                                }
                                case 1: {
                                        db.createObjectStore("worklistStore", { keyPath: "id", autoIncrement: true })
                                }
                        }

                        console.log("indexeddb: opened store")
                }
                request.onsuccess = (event) => {
                        db = event.target.result
                        console.log("indexeddb: opened database")
                        resolve()
                }
                request.onerror = (event) => {
                        console.error("indexeddb: cannot open database")
                        reject()
                }
        })
}

const addChecklist = (checklist) => {
        return new Promise((resolve, reject) => {
                if (!db) return reject(new Error("indexeddb: not yet open")) // TODO
                if (!checklist.name) return reject(new Error("name is required"))
                if (!checklist.entries) return reject(new Error("entries is required"))
                if (!checklist.entries.length) return reject(new Error("entries is required"))

                const transaction = db.transaction("checklistStore", "readwrite")
                const store = transaction.objectStore("checklistStore")
                const request = store.add(checklist)
                request.onsuccess = () => {
                        console.log("indexeddb: added checklist to store")
                        transaction.oncomplete = resolve
                        transaction.onerror = reject
                }
                request.onerror = () => {
                        console.log("indexeddb: cannot add checklist")
                        reject()
                }
        })
}

const updateChecklist = (checklist) => {
        return new Promise((resolve, reject) => {
                if (!db) return reject() // TODO

                const transaction = db.transaction("checklistStore", "readwrite")
                const store = transaction.objectStore("checklistStore")
                const request = store.put(checklist)
                request.onsuccess = () => {
                        console.log("indexeddb: added checklist to store")
                        transaction.oncomplete = resolve
                        transaction.onerror = reject
                }
                request.onerror = () => {
                        console.log("indexeddb: cannot add checklist")
                        reject()
                }
        })
}

const getChecklists = () => {
        return new Promise((resolve, reject) => {
                if (!db) return reject(new Error("indexeddb: not yet open")) // TODO
                const transaction = db.transaction("checklistStore", "readonly")
                const store = transaction.objectStore("checklistStore")
                const request = store.getAll()
                request.onsuccess = (event) => {
                        console.log("indexeddb: retrieved all checklists")
                        resolve(event.target.result)
                }
                request.onerror = () => {
                        console.log("indexeddb: cannot get checklists")
                        reject(new Error("indexeddb: cannot get checklists"))
                }
        })
}

const getChecklist = (id) => {
        return new Promise((resolve, reject) => {
                if (!db) return reject(new Error("indexeddb: not yet open")) // TODO
                const transaction = db.transaction("checklistStore", "readonly")
                const store = transaction.objectStore("checklistStore")
                const request = store.get(id)
                request.onsuccess = (event) => {
                        console.log("indexeddb: retrieved checklist")
                        resolve(event.target.result)
                }
                request.onerror = () => {
                        console.log(`indexeddb: cannot get checklist id: ${id}`)
                        reject(new Error(`indexeddb: cannot get checklist id: ${id}`))
                }
        })
}

const deleteChecklist = (id) => {
        return new Promise((resolve, reject) => {
                if (!db) return reject(new Error("indexeddb: not yet open"))
                const transaction = db.transaction("checklistStore", "readwrite")
                const store = transaction.objectStore("checklistStore")
                const request = store.delete(id)
                request.onsuccess = () => {
                        console.log("indexeddb: deleted checklist")
                        resolve()
                }
                request.onerror = () => {
                        console.log(`indexeddb: cannot delete checklist id: ${id}`)
                        reject(new Error(`indexeddb: cannot delete checklist id: ${id}`))
                }
        })
}

const addWorklist = (worklist) => {
        return new Promise((resolve, reject) => {
                if (!db) reject(new Error("indexeddb: not yet open"))
                const transaction = db.transaction("worklistStore", "readwrite")
                const store = transaction.objectStore("worklistStore")
                const request = store.add({
                        ...worklist,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                })

                request.onsuccess = () => {
                        console.log("indexeddb: added worklist")
                        resolve()
                }
                request.onerror = (error) => {
                        console.log("indexeddb: cannot add worklist")
                        reject(error)
                }
        })
}

const updateWorklist = (worklist) => {
        return new Promise((resolve, reject) => {
                if (!db) reject(new Error("indexeddb: not yet open"))
                const transaction = db.transaction("worklistStore", "readwrite")
                const store = transaction.objectStore("worklistStore")
                const request = store.openCursor(worklist.id)

                request.onsuccess = (event) => {
                        const cursor = event.target.result
                        if (cursor) {
                                const value = cursor.value
                                Object.assign(value, worklist, { updatedAt: new Date() })
                                const request = cursor.update(cursor.value)

                                request.onsuccess = () => {
                                        console.log("indexeddb: updated worklist")
                                        resolve()
                                }
                                request.onerror = (error) => {
                                        console.log("indexeddb: cannot update worklist")
                                        reject(error)
                                }
                        } else {
                                console.log("indexeddb: cannot update worklist")
                                reject(error)
                        }
                }
                request.onerror = (error) => {
                        console.log("indexeddb: cannot update worklist")
                        reject(error)
                }
        })
}

const getWorklists = (checklistId) => {
        return new Promise((resolve, reject) => {
                if (!db) return reject(new Error("indexeddb: not yet open")) // TODO
                const transaction = db.transaction("worklistStore", "readonly")
                const store = transaction.objectStore("worklistStore")
                if (checklistId) {
                        const request = store.openCursor()
                        const result = []
                        request.onsuccess = (event) => {
                                const cursor = event.target.result

                                if (cursor) {
                                        const value = cursor.value
                                        if (value.checklistId == checklistId) {
                                                result.push(value)
                                        }
                                        cursor.continue()
                                } else {
                                        console.log("indexeddb: retrieved all worklists")
                                        resolve(result)
                                }
                        }
                        request.onerror = () => {
                                console.log(`indexeddb: cannot get worklists of checklist id: ${checklistId}`)
                                reject(new Error(`indexeddb: cannot get worklists of checklist id: ${checklistId}`))
                        }
                } else {
                        const request = store.getAll()
                        request.onsuccess = (event) => {
                                console.log("indexeddb: retrieved worklists")
                                resolve(event.target.result)
                        }
                        request.onerror = () => {
                                console.log("indexeddb: cannot get worklists")
                                reject(new Error("indexeddb: cannot get worklists"))
                        }
                }
        })
}

const hasWorklist = (checklistId) => {
        return new Promise((resolve, reject) => {
                if (!db) return reject(new Error("indexeddb: not yet open")) // TODO
                const transaction = db.transaction("worklistStore", "readonly")
                const store = transaction.objectStore("worklistStore")
                const request = store.openCursor()
                const result = []
                request.onsuccess = (event) => {
                        const cursor = event.target.result

                        if (cursor) {
                                const value = cursor.value
                                if (value.checklistId == checklistId) {
                                        resolve(true)
                                } else {
                                        cursor.continue()
                                }
                        } else {
                                resolve(false)
                        }
                }
                request.onerror = () => {
                        console.log(`indexeddb: cannot get worklists of checklist id: ${checklistId}`)
                        reject(new Error(`indexeddb: cannot get worklists of checklist id: ${checklistId}`))
                }
        })
}

const deleteWorklist = (id) => {
        return new Promise((resolve, reject) => {
                if (!db) return reject(new Error("indexeddb: not yet open")) // TODO
                const transaction = db.transaction("worklistStore", "readwrite")
                const store = transaction.objectStore("worklistStore")
                const request = store.delete(id)
                request.onsuccess = (event) => {
                        console.log(`indexeddb: deleted worklist id: ${id}`)
                        resolve()
                }
                request.onerror = (event) => {
                        const message = `indexeddb: cannot delete worklist id: ${id}`
                        console.log(message)
                        reject(new Error(message))
                }
        })
}

const createForm = (options = { success: "SUBMIT", id: "", name: "", entries: [], title: "" }) => {
        console.assert(options.success, "REQUIRES { success }; this is the event type")

        const template = document.querySelector("#t-checklist-form")
        const result = template.content.cloneNode(true)
        const form = result.querySelector("#form")
        const name = result.querySelector(".input-name")
        const entries = result.querySelector(".entries")

        form.addEventListener("submit", (event) => {
                actor.send({ type: options.success }) // TODO: pass the form here?
                history.pushState({ name: actor.state() }, "")
                event.preventDefault()
        })
        result.querySelector(".add-entry").addEventListener("click", () => {
                const entryGroup = createEntryGroup()
                entries.insertBefore(entryGroup, entries.lastElementChild)
        })

        if (options.entries && options.entries.length > 0) {
                options.entries.forEach((value, index) => {
                        const entryGroup = createEntryGroup({ value, required: index == 0 })
                        entries.insertBefore(entryGroup, entries.lastElementChild)
                })
        } else {
                const entryGroup = createEntryGroup({ required: true })
                entries.insertBefore(entryGroup, entries.lastElementChild)
        }

        if (options.id) {
                form.dataset.id = options.id
        }

        if (options.name) {
                name.value = options.name
        }

        if (options.title) {
                const title = result.querySelector(".subtitle")
                title.textContent = options.title
        }

        return result
}

const createEntryGroup = (options = { required: false, value: "" }) => {
        const template = document.querySelector("#t-entry-group")
        const result = template.content.cloneNode(true)
        const button = result.querySelector("button")
        const input = result.querySelector("input")
        if (options.required) {
                input.required = true
                button.remove()
        } else {
                button.addEventListener("click", (event) => {
                        event.target.parentNode.remove()
                })
        }
        if (options.value) {
                input.value = options.value
        }
        return result
}

const getChecklistForm = (form) => {
        const name = form["name"].value
        let entries = form["entry"]
        if (!(entries instanceof NodeList)) {
                entries = [entries]
        } else {
                entries = Array.from(entries)
        }
        entries = entries.map((i) => i.value).filter((v) => v)
        const result = { name, entries }

        if (form.dataset.id) {
                result.id = Number(form.dataset.id)
        }

        return result
}

// TODO:
const listMachine = {
        current: "idle",
        states: {
                idle: {
                        on: {
                                ACTIVATE: "active",
                        },
                },
                active: {
                        on: {
                                CLICK: "idle",
                                SWIPE: "swiped",
                                SCROLL: "idle",
                        },
                },
                swiped: {
                        on: {
                                DONE: "idle",
                                RESET: "idle",
                                UPDATE: "swiped",
                        },
                },
        },
}
const listActor = createActor(listMachine)
listActor.subscribe(({ state, event }) => {
        switch (state) {
                case "idle": {
                        app.style.setProperty("overflow-y", "auto")
                        listItemAnimate({ element: event.value.element, value: event.value.data })
                } break
                case "active": {
                        // NOTE: do nothing?
                } break
                case "swiped": {
                        app.style.setProperty("overflow-y", "hidden")
                        listItemAnimate({ element: event.value.element, value: event.value.data })
                } break
        }
})

const createPosition = () => ({
        element: null,
        x: 0,
        y: 0,
})
const positionStart = createPosition()
const positionEnd = createPosition()
const delta = ({ x: xStart, y: yStart }, { x: xEnd, y: yEnd }) => {
        const result = {}
        result.deltaX = xEnd - xStart
        result.deltaY = yEnd - yStart
        result.isScrolled = (result.deltaY != 0) && (Math.abs(Math.abs(result.deltaY) - Math.abs(result.deltaX)) < 10)
        result.isSwiped = (result.deltaX != 0) && (Math.abs(result.deltaX) > Math.abs(result.deltaY))
        result.isLeft = (xEnd - xStart) < 0
        result.isRight = (xEnd - xStart) > 0
        return result
}
const listItemAnimate = ({ element, value }) => {
        requestAnimationFrame(() => {
                element.style.setProperty(
                        "--translate-x",
                        (typeof value == "string") ? value : `${value}px`
                )
        })
}

const attachSwipeLogic = (item) => {
        item.addEventListener("pointerdown", (event) => {
                if (event.target.tagName == "BUTTON") return

                const element = event.currentTarget
                Object.assign(positionStart, { element, x: event.x, y: event.y })
                switch (listActor.state()) {
                        case "idle": {
                                listActor.send({ type: "ACTIVATE" })
                                const onMove = (event) => {
                                        Object.assign(positionEnd, { element: event.target, x: event.x, y: event.y })
                                        switch (listActor.state()) {
                                                case "active": {
                                                        const result = delta(positionStart, positionEnd)
                                                        if (result.isScrolled) {
                                                                listActor.send({ type: "SCROLL", value: { element: positionStart.element, data: 0 } })
                                                                window.removeEventListener("pointermove", onMove)
                                                                window.removeEventListener("pointerup", onUp)
                                                        } else if (result.isSwiped) {
                                                                listActor.send({ type: "SWIPE", value: { element: positionStart.element, data: result.deltaX } })
                                                        } else {
                                                                // NOTE: noop
                                                        }
                                                } break
                                                case "swiped": {
                                                        const result = delta(positionStart, positionEnd)
                                                        listActor.send({ type: "UPDATE", value: { element: positionStart.element, data: result.deltaX } })
                                                }
                                        }
                                }

                                const onUp = (event) => {
                                        Object.assign(positionEnd, { element: event.target, x: event.x, y: event.y })
                                        switch (listActor.state()) {
                                                case "active": {
                                                        listActor.send({ type: "CLICK", value: { element: positionStart.element, data: 0 } })

                                                        const checklistId = Number(positionStart.element.dataset.id)
                                                        getChecklist(checklistId)
                                                                .then((checklist) => {
                                                                        actor.send({ type: "PREVIOUS", value: checklist })
                                                                        history.pushState({ name: actor.state(), value: checklist }, "")
                                                                })
                                                                .catch((error) => {
                                                                        // TODO: handle error
                                                                        console.error(error)
                                                                })
                                                } break
                                                case "swiped": {
                                                        // TODO: handle full swipe
                                                        const result = delta(positionStart, positionEnd)
                                                        const { width } = positionStart.element.getBoundingClientRect()
                                                        if (Math.abs(result.deltaX / width) > 0.10) {
                                                                listActor.send({
                                                                        type: "UPDATE",
                                                                        value: {
                                                                                element: positionStart.element,
                                                                                data: result.isLeft ? "-16%" : "16%",
                                                                        }
                                                                })
                                                        } else {
                                                                listActor.send({
                                                                        type: "RESET",
                                                                        value: { element: positionStart.element, data: 0 }
                                                                })
                                                        }
                                                        Object.assign(positionEnd, positionStart)
                                                } break
                                        }

                                        window.removeEventListener("pointermove", onMove)
                                        window.removeEventListener("pointerup", onUp)
                                }

                                window.addEventListener("pointermove", onMove)
                                window.addEventListener("pointerup", onUp)
                        } break
                        case "active": { /* NOTE: do nothing? */ } break
                        // case "swiped": {
                        //         // TODO: continue swiping
                        // } break
                        default: {
                                // positionStart.element.style.setProperty("--translate-x", `0px`)
                                listActor.send({ type: "RESET", value: { element: positionEnd.element, data: 0 } })
                        }
                }
        })
}

const createChecklistItem = (checklist) => {
        const template = document.querySelector("#t-checklist-item")
        const result = template.content.cloneNode(true)
        const item = result.querySelector("li")
        const name = result.querySelector(".name")
        const buttonNew = result.querySelector(".new")
        const buttonEdit = result.querySelector(".edit")
        const buttonRemove = result.querySelector(".remove")

        item.dataset.id = checklist.id
        name.textContent = checklist.name

        attachSwipeLogic(item)

        buttonNew.addEventListener("click", (event) => {
                event.stopPropagation()
                actor.send({ type: "SESSION", value: { checklistId: checklist.id } })
                history.pushState({ name: actor.state(), value: { checklistId: checklist.id } }, "")
        })

        buttonEdit.addEventListener("click", (event) => {
                event.stopPropagation()
                actor.send({ type: "EDIT", value: checklist.id })
                history.pushState({ name: actor.state(), value: checklist.id }, "")
        })
        buttonRemove.addEventListener("click", (event) => {
                event.stopPropagation()

                const template = document.querySelector("#t-confirm-delete")
                const result = template.content.cloneNode(true)
                const name = result.querySelector(".name")
                const cancel = result.querySelector(".cancel")
                const proceed = result.querySelector(".delete")

                name.textContent = checklist.name

                cancel.addEventListener("click", () => {
                        modal.close()
                })

                proceed.addEventListener("click", () => {
                        deleteChecklist(checklist.id)
                                .then(() => item.remove())
                                .then(() => modal.close())
                })

                modal.innerHTML = ""
                modal.appendChild(result)
                modal.showModal()
        })

        return result
}

const createSessionEntry = ({ entry, form }) => {
        const template = document.querySelector("#t-session-item")
        const result = template.content.cloneNode(true)

        const item = result.querySelector(".name")
        item.textContent = entry.name
        item.dataset.description = entry.description || ""

        const input = result.querySelector("input")
        input.checked = !!entry.value
        if (form) {
                input.addEventListener("change", () => {
                        const worklist = getWorklistForm(form)
                        updateWorklist(worklist).then(
                                () => {
                                        // TODO: notify user that it is saved
                                },
                                (error) => {
                                        // TODO: handle error
                                        console.error(error)
                                }
                        )
                })
        }

        const description = result.querySelector(".description")
        description.textContent = entry.description

        const button = result.querySelector(".describe")
        button.addEventListener("click", () => {
                const template = document.querySelector("#t-session-item-description")
                const result = template.content.cloneNode(true)

                const input = result.querySelector(".input")
                input.value = item.dataset.description

                const cancel = result.querySelector(".cancel")
                cancel.addEventListener("click", () => {
                        modal.close()
                })

                const ok = result.querySelector(".save")
                ok.addEventListener("click", () => {
                        item.dataset.description = input.value
                        description.textContent = input.value

                        if (form) {
                                const worklist = getWorklistForm(form)
                                updateWorklist(worklist).then(
                                        () => {
                                                // TODO: notify user that it is saved
                                        },
                                        (error) => {
                                                // TODO: handle error
                                                console.error(error)
                                        }
                                )
                        }

                        modal.close(input.value)
                })

                modal.innerHTML = ""
                modal.appendChild(result)
                modal.showModal()
        })

        return result
}

const createNewSession = (checklist) => {
        const template = document.querySelector("#t-session-form")
        const result = template.content.cloneNode(true)

        const name = result.querySelector(".name")
        name.textContent = checklist.name
        name.dataset.checklistId = checklist.id

        const worklist = result.querySelector("#list")
        worklist.innerHTML = ""
        const entries = checklist.entries
                .map((v) => ({ entry: { name: v } }))
                .map(createSessionEntry)
        if (entries.length > 0) {
                worklist.append(...entries)
        } else {
                const empty = document.createElement("li")
                empty.classList.add("empty")
                empty.textContent = "Nothing to see here"
                worklist.appendChild(empty)
        }

        const button = result.querySelector(".save")
        button.addEventListener("click", () => {
                actor.send({ type: "SAVE" })
                history.pushState({ name: actor.state() }, "")
        })

        return result
}

const createPreviousSession = ({ previousWorklist, checklist }) => {
        const template = document.querySelector("#t-session-form")
        const result = template.content.cloneNode(true)

        const checklistName = result.querySelector(".name")
        checklistName.textContent = checklist.name
        checklistName.dataset.checklistId = checklist.id
        checklistName.dataset.worklistId = previousWorklist.id

        const form = result.querySelector("#form")
        const name = result.querySelector("#session-name")
        name.value = previousWorklist.name
        name.addEventListener("change", (event) => {
                const worklist = getWorklistForm(form)
                updateWorklist(worklist).then(
                        () => {
                                // TODO: notify user that it is saved
                        },
                        (error) => {
                                // TODO: handle error
                                console.error(error)
                        }
                )
        })

        const worklist = result.querySelector("#list")
        worklist.innerHTML = ""
        const entries = previousWorklist.entries.map(
                (entry) => createSessionEntry({
                        entry,
                        form,
                })
        )
        if (entries.length > 0) {
                worklist.append(...entries)
        } else {
                const empty = document.createElement("li")
                empty.classList.add("empty")
                empty.textContent = "Nothing to see here"
                worklist.appendChild(empty)
        }

        const button = result.querySelector(".save")
        button.addEventListener("click", () => {
                actor.send({ type: "SAVE" })
                history.pushState({ name: actor.state() }, "")
        })

        return result
}

const getWorklistForm = (form) => {
        const result = {}

        const checklistName = form.querySelector(".name")
        const name = form.querySelector("#session-name").value || checklistName.textContent

        result.name = name

        result.checklistId = Number(checklistName.dataset.checklistId)
        if (checklistName.dataset.worklistId) {
                result.id = Number(checklistName.dataset.worklistId)
        }

        let entries = form.entry
        if (!(entries instanceof NodeList)) {
                entries = [entries]
        } else {
                entries = Array.from(entries)
        }
        result.entries = entries.map((input) => {
                const container = input.parentNode.querySelector(".name")
                const name = container.textContent
                const value = input.checked
                const description = container.dataset.description
                return { name, value, description }
        })

        return result
}

const createPreviousWorklists = ({ checklist, worklists }) => {
        const templateList = document.querySelector("#t-previous")
        const result = templateList.content.cloneNode(true)
        const name = result.querySelector(".previous-name")
        const list = result.querySelector("#list")

        const templateItem = document.querySelector("#t-previous-item")
        const worklistItems = worklists.map((worklist) => {
                const result = templateItem.content.cloneNode(true)

                const item = result.querySelector("li")

                const date = result.querySelector("time")
                const datestring = getHumanDateDiff(worklist.createdAt, new Date())
                date.setAttribute("datetime", worklist.createdAt)
                date.setAttribute("title", worklist.createdAt)
                date.textContent = datestring

                const name = result.querySelector(".name")
                name.textContent = worklist.name

                const edit = result.querySelector(".edit")
                edit.addEventListener("click", () => {
                        const checklistId = checklist.id
                        actor.send({ type: "SESSION", value: { checklistId, worklist } })
                        history.pushState({ name: actor.state(), value: { checklistId, worklist } }, "")
                })

                const exportWorklist = result.querySelector(".export")
                exportWorklist.addEventListener("click", (event) => {
                        event.stopPropagation()
                        exportToJson(worklist)
                })

                const remove = result.querySelector(".remove")
                remove.addEventListener("click", (event) => {
                        event.stopPropagation()
                        const template = document.querySelector("#t-confirm-delete")
                        const result = template.content.cloneNode(true)
                        const name = result.querySelector(".name")
                        const cancel = result.querySelector(".cancel")
                        const proceed = result.querySelector(".delete")

                        name.textContent = worklist.name

                        cancel.addEventListener("click", () => {
                                modal.close()
                        })

                        proceed.addEventListener("click", () => {
                                deleteWorklist(worklist.id)
                                        .then(() => item.remove())
                                        .then(() => modal.close())
                        })

                        modal.innerHTML = ""
                        modal.appendChild(result)
                        modal.showModal()
                })

                return result
        })
        name.textContent = checklist.name
        list.append(...worklistItems)
        return result
}

document.addEventListener("DOMContentLoaded", () => {
        if ("serviceWorker" in navigator) {
                navigator.serviceWorker.register("/sw.js");
        }

        const page = document.querySelector("#app .page")

        const render = ({ id, node }) => {
                console.assert(id, "REQUIRES { id }")
                console.assert(node, "REQUIRES { node }")

                page.innerHTML = ""
                page.appendChild(node)
                page.id = id
        }

        actor.subscribe(({ state, event }) => {
                switch (state) {
                        case "index": {
                                const template = document.querySelector("#t-p-index")
                                const result = template.content.cloneNode(true)
                                const add = result.querySelector(".add")
                                const upload = result.querySelector(".upload")
                                const input = result.querySelector(".input")
                                const list = result.querySelector("#list")

                                add.addEventListener("click", () => {
                                        actor.send({ type: "CREATE" })
                                        history.pushState({ name: actor.state() }, "")
                                })

                                upload.addEventListener("click", () => {
                                        input.click()
                                })

                                input.addEventListener("change", (event) => {
                                        // TODO: should we handle multiple files?
                                        const [file] = event.target.files
                                        if (!file) return
                                        console.log(file)
                                        file.text()
                                                .then(toJson)
                                                .then(addChecklist)
                                                .then(() => {
                                                        // TODO: deduplicate; try to reuse "adding" state
                                                        getChecklists()
                                                                .then((cs) => {
                                                                        if (cs.length > 0) {
                                                                                list.append(...cs.map(createChecklistItem))
                                                                        } else {
                                                                                const empty = document.createElement("li")
                                                                                empty.classList.add("empty")
                                                                                empty.textContent = "Nothing to see here"
                                                                                list.append(empty)
                                                                        }
                                                                })
                                                                .catch((error) => console.log(error))
                                                })
                                                .catch((error) => {
                                                        // TODO: handle error
                                                        console.error(error)
                                                })
                                })

                                getChecklists()
                                        .then((cs) => {
                                                if (cs.length > 0) {
                                                        list.append(...cs.map(createChecklistItem))
                                                } else {
                                                        const empty = document.createElement("li")
                                                        empty.classList.add("empty")
                                                        empty.textContent = "Nothing to see here"
                                                        list.append(empty)
                                                }
                                        })
                                        .catch((error) => console.log(error))

                                render({ id: "p-index", node: result })
                        } break
                        case "create": {
                                const form = createForm()
                                render({ id: "p-create", node: form })
                        } break
                        case "adding": {
                                const form = page.querySelector("#form") // TODO: retrieve form from event
                                const checklist = getChecklistForm(form)
                                addChecklist(checklist)
                                        .then(() => {
                                                actor.send({ type: "ADD_SUCCESS" })
                                                history.pushState({ name: actor.state() }, "")
                                        },
                                                () => actor.send({ type: "ADD_FAILURE" }))
                        } break
                        case "edit": {
                                getChecklist(event.value)
                                        .then((checklist) => {
                                                const form = createForm({
                                                        ...checklist,
                                                        success: "UPDATE",
                                                        title: "Edit Checklist"
                                                })
                                                render({ id: "p-edit", node: form })
                                        })
                        } break
                        case "updating": {
                                const form = page.querySelector("#form") // TODO: retrieve form from event
                                const checklist = getChecklistForm(form)
                                updateChecklist(checklist)
                                        .then(() => {
                                                actor.send({ type: "UPDATE_SUCCESS" })
                                                history.pushState({ name: actor.state() }, "")
                                        },
                                                () => actor.send({ type: "UPDATE_FAILURE" }))
                        } break
                        case "session": {
                                getChecklist(event.value.checklistId)
                                        .then((checklist) => {
                                                let session
                                                if (!event.value.worklist) {
                                                        session = createNewSession(checklist)
                                                } else {
                                                        session = createPreviousSession({
                                                                previousWorklist: event.value.worklist,
                                                                checklist,
                                                        })
                                                }
                                                render({ id: "p-session", node: session })
                                        })
                        } break
                        case "saving": {
                                const form = page.querySelector("#form") // TODO: retrieve form from event
                                const worklist = getWorklistForm(form)
                                if (worklist.id) {
                                        updateWorklist(worklist)
                                                .then(() => {
                                                        actor.send({ type: "SAVE_SUCCESS" })
                                                        history.pushState({ name: actor.state() }, "")
                                                },
                                                        (error) => {
                                                                // TODO:
                                                                console.error(error)
                                                                actor.send({ type: "SAVE_FAILURE" })
                                                        })
                                } else {
                                        addWorklist(worklist)
                                                .then(() => {
                                                        actor.send({ type: "SAVE_SUCCESS" })
                                                        history.pushState({ name: actor.state() }, "")
                                                },
                                                        (error) => {
                                                                // TODO:
                                                                console.error(error)
                                                                actor.send({ type: "SAVE_FAILURE" })
                                                        })
                                }
                        } break
                        case "previous": {
                                getWorklists(event.value.id)
                                        .then((worklists) => {
                                                const result = createPreviousWorklists({ checklist: event.value, worklists })
                                                render({ id: "p-previous", node: result })
                                        })
                        } break
                        default: {
                                console.assert(false, "INVALID STATE")
                        }
                }
        })
        document.querySelector("#app #header a").addEventListener("click", (event) => {
                event.preventDefault()
                // TODO: implement refresh when already at home "/"
                actor.send({ type: "CANCEL" })
                history.pushState({ name: actor.state() }, "")
        })
        app.addEventListener("click", (event) => {
                const element = event.target
                if (!(element && element.classList)) return
                if (!(element.tagName == "BUTTON" && element.classList.contains("back"))) return
                history.back()
        })

        window.addEventListener("popstate", (event) => {
                if (!(event.state && event.state.name)) {
                        console.error(new Error("INVALID POPSTATE"))
                        return
                }

                const current = actor.state()
                const next = event.state.name
                console.log("current:", actor.state(), "next:", next)
                switch (actor.state()) {
                        case "index": {
                                switch (next) {
                                        case "create": {
                                                actor.send({ type: "CREATE" })
                                        } break
                                        case "edit": {
                                                actor.send({ type: "EDIT", value: event.state.value })
                                        } break
                                        case "session": {
                                                actor.send({ type: "SESSION", value: event.state.value })
                                        } break
                                        case "previous": {
                                                actor.send({ type: "PREVIOUS", value: event.state.value })
                                        } break
                                }
                        } break
                        case "session": {
                                switch (next) {
                                        case "index": {
                                                actor.send({ type: "CANCEL" })
                                        } break
                                        case "previous": {
                                                actor.send({ type: "PREVIOUS", value: event.state.value })
                                        } break
                                }
                        } break
                        case "previous": {
                                switch (next) {
                                        case "index": {
                                                actor.send({ type: "CANCEL" })
                                        } break
                                        case "session": {
                                                actor.send({ type: "SESSION", value: event.state.value })
                                        } break
                                }
                        } break
                        default: {
                                if (next == "index")
                                        actor.send({ type: "CANCEL" })
                        }
                }
        })

        openDatabase()
                .then(actor.transitioned)
                .then(() => history.replaceState({ name: actor.state() }, ""))
        // // TMP: TEST CODE
        //      .then(() => {
        //              getChecklists()
        //                      .then((cs) => {
        //                              if (cs.length < 20) {
        //                                      Array(20 - cs.length).fill(0).forEach(() => {
        //                                              addChecklist({ name: "foo", entries: [] })
        //                                      })
        //                              }
        //                      })
        //      })

        let deferredPrompt
        let isInstallDismissed = false
        const showInstallPrompt = () => {
                const template = document.querySelector("#t-install-prompt")
                const result = template.content.cloneNode(true)
                const cancel = result.querySelector(".cancel")
                const install = result.querySelector(".install")

                install.addEventListener("click", async () => {
                        deferredPrompt.prompt()

                        deferredPrompt.userChoice.then((result) => {
                                const { outcome } = result
                                deferredPrompt = null

                                if (outcome === "accepted") {
                                        console.log("pwa: installed")
                                } else if (outcome === "dismissed") {
                                        isInstallDismissed = true
                                        console.log("pwa: cancel install")
                                }

                                modal.close()
                        })
                })

                cancel.addEventListener("click", () => {
                        modal.close()
                })

                modal.innerHTML = ""
                modal.appendChild(result)
                modal.showModal()
        }

        window.addEventListener("beforeinstallprompt", (event) => {
                event.preventDefault()
                deferredPrompt = event
                // NOTE: when install is dismissed, "beforeinstallprompt"
                // is fired again for some reason
                if (!isInstallDismissed) {
                        showInstallPrompt()
                }
        })
})

