import { dom, pick } from "@schtappe/utils"

const attributes = ["id", "name", "checklistId", "entries", "createdAt", "updatedAt"]

export const Worklist = (data = {}) => {
        return pick(attributes, data)
}

export const exportToJson = (worklist) => {
        const name = `${worklist.name}.json`
        const file = new File([JSON.stringify(worklist, null, 8)], name)
        dom.downloadBlob(file, name)
}
